<template>
  <div>
    <div
        v-touch:swipe.left="toggleMenu"
        class="ui left sidebar red main vertical menu"
        :class="{ 'visible': mobile }"
    >
      <template v-if="user">
        <a
v-if="user.active_pricelist === 'sales'" class="item" href="#"
           @click.prevent="changePricelist('default')">
          {{ __('Ostuvaade') }}
        </a>
        <a v-else class="item" href="#" @click.prevent="changePricelist('sales')">
          {{ __('Müügivaade') }}
        </a>
        <router-link
v-if="user && user.b2b_client && user.b2b_client.can_offers" class="item"
                     to="/pakkumised" @click.native="toggleMenu">{{
            __('Pakkumised')
          }}
        </router-link>
        <router-link class="item" to="/tellimused" @click.native="toggleMenu">
          <i class="truck icon"></i>
          {{ __('Tellimused') }}
        </router-link>
        <router-link class="item" to="/arved" @click.native="toggleMenu">
          <i class="file alternate icon"></i>
          {{ __('Arved') }}
        </router-link>
        <router-link class="item" to="/seaded" @click.native="toggleMenu">
          <i class="cog icon"></i>
          {{ __('Seaded') }}
        </router-link>
        <a id="logout-btn" class="item" href="#" @click.prevent="doLogout">
          <i class="ui fitted sign out right floated icon"></i>
          {{ __('Välju') }}
        </a>
        <router-link class="item" to="/eripakkumised" @click.native="toggleMenu">{{ __('Eripakkumised') }}</router-link>
        <RowAccordion
v-for="(item, k) in menu" :key="k" class="ui accordion" :toggled="false"
                   :title="item.title ? item.title : ''" :class="'c-' + item.id">
          <template v-if="item.two_columns">
            <template v-for="part in item.children">
              <div v-for="(child, i) in part" :key="i" class="ui link list">
                <router-link class="item" :to="url(child.url)" @click.native="toggleMenu">{{
                    child.title
                  }}
                </router-link>
              </div>
            </template>
          </template>
          <template v-else>
            <div v-for="(child, i) in item.children" :key="i" class="ui link list">
              <router-link class="item" :to="url(child.url)" @click.native="toggleMenu">{{ child.title }}
              </router-link>
            </div>
          </template>
        </RowAccordion>
      </template>
      <template v-else>
        <router-link class="item" to="/login" @click.native="toggleMenu">
          <i class="user icon"></i>
          {{ __('Sisene') }}
        </router-link>
        <router-link class="item" to="/password/reset" @click.native="toggleMenu">
          <i class="redo icon"></i>
          {{ __('Unustasid salasõna?') }}
        </router-link>
      </template>
      <a class="item" :href="publicUrl">
        <i class="external share icon"></i>
        {{ __('Jaekliendile') }}
      </a>
      <a href="tel:003726650073" class="item">
        <i class="phone icon"></i>
        {{ __('665 0073; 665 0078') }}
      </a>
      <router-link class="item" to="/kontakt" @click.native="toggleMenu">
        <i class="envelope outline icon"></i>
        {{ __('Kontakt') }}
      </router-link>
      <div class="item">
        {{ __('Kasulikud viited') }}
        <div class="menu">
          <router-link class="item" to="/hooldus" @click.native="toggleMenu">{{ __('Hooldus') }}</router-link>
          <router-link class="item" to="/garantii" @click.native="toggleMenu">{{ __('Garantii') }}</router-link>
          <router-link class="item" to="/tellimine" @click.native="toggleMenu">{{ __('Tellimine') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import {__, url} from '../utils';
import RowAccordion from "./RowAccordion.vue";

const publicUrl = import.meta.env.VITE_PUBLIC_URL;

export default {
  name: "MenuMobile",
  components: {RowAccordion},
  data: () => ({
    publicUrl
  }),
  methods: {
    url,
    __,
    ...mapMutations('Loader', {toggleMenu: 'TOGGLE_MENU'}),
    ...mapActions('User', ['pricelistChange', 'logout']),
    changePricelist(type) {
      this.toggleMenu();
      this.pricelistChange(type);
    },
    doLogout() {
      this.toggleMenu();
      this.logout().then(() => {
        this.$router.push('/login');
        this.setUser(null);
      }).catch(() => {
      });
    },
  },
  computed: {
    ...mapState('Loader', ['mobile']),
    ...mapState('User', ['user']),
    ...mapState('Page', ['menu']),
  }
}
</script>

<style scoped>
.ui.visible.sidebar {
  overflow-x: hidden !important;
  z-index: 200;
}

.ui.visible.sidebar > .item:not(.accordion) {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ui.visible.sidebar > .item:not(.accordion) .item {
  padding-bottom: 8px;
  padding-top: 8px;

}

.ui.visible.sidebar .item i.icon {
  float: none;
  margin: 0 .35714286em 0 0;
}
</style>
